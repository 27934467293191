import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_PROGRAMMES_SUCCESS,
  USER_PROGRAMMES_FAIL,
  LOGIN_INIT,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  CF_USER_DETAILS_SUCCESS,
  CF_USER_DETAILS_FAIL
} from '../actions/types';

const initialState = {
  loading: false,
  userData: null,
  cfUserData: null,
  userProgrammes: null,
  profileData: null,
  isAuthenticated: false,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_INIT:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem('token', JSON.stringify(payload));
      // localStorage.setItem('authenticated', true);
      console.log(payload)
      window.location.assign('/home')
      return {
        ...state,
        userData: payload,
        isAuthenticated: true,
        loading: false,
      };
    case LOGIN_FAIL:
    case REGISTER_FAIL:
      localStorage.removeItem('token')
      // localStorage.setItem('authenticated', false);
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case LOGOUT:
      // localStorage.setItem('authenticated', false);
      localStorage.removeItem('token')
      // window.location.replace('/login')
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        userData: payload,
        loading: false,
      };
    case USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        profileData: payload,
        loading: false,
      };
    case USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case USER_PROGRAMMES_SUCCESS:
      return {
        ...state,
        userProgrammes: payload,
        loading: false,
      };
    case USER_PROGRAMMES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CF_USER_DETAILS_SUCCESS:
      return {
        ...state,
        cfUserData: payload,
        loading: false,
      };
    case CF_USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
