import { API_GET_TIGERCAT_BURNS_GET_NOTES } from './constants/tigercat-operations-constants';
import {
    GET_TIGERCAT_BURNS_GET_NOTES_FAIL,
    GET_TIGERCAT_BURNS_GET_NOTES_SUCCESS
} from './types/tigercat-operations-types';

import { setAlert } from '../alert';
import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
};

export const getNoteLogs = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_NOTES + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_NOTES_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_NOTES_FAIL,
            payload: err.response.data.detail,
        });
    }
};