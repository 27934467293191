import React, { useState, useEffect } from 'react'
import { Box, TextField, Tooltip, Button, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, CircularProgress, Typography } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getBiocharMassBags, postBiocharMassBagMoistureContent } from '../../../../../actions/tigercat-burns/tigercat-actions';
import { v4 as uuidv4 } from 'uuid';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
const BiocharMassBags = ({ projectId }) => {
    const [biocharMassBagsState, setBiocharMassBagsState] = useState([])
    const dispatch = useDispatch()
    const biocharMassBags = useSelector(state => state.tigercatBurnsReducer.biocharMassBags.data)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(getBiocharMassBags(projectId));
        console.log(biocharMassBags)
    }, [dispatch, projectId]);

    useEffect(() => {
        // console.log('show it', biocharMassBags)
        const formDataArray = biocharMassBags?.map((item) => {
            const formData = new FormData();
            formData.append('id', item.id);
            formData.append('date', item.created_at.split('T')[0]);
            formData.append('user', item.user_name)
            formData.append('mbId', item.mbId);
            formData.append('massOfBag', item.massOfBag);
            formData.append('moistureContent', item.moistureContent);
            formData.append('measurementPhoto', item.measurementPhoto);
            formData.append('dryMass', item.dryMass);
            formData.append('rowId', uuidv4())
            formData.append('disabled', item.moistureContent ? true : false);
            return formData;
        });
        setBiocharMassBagsState(formDataArray);
    }, [biocharMassBags]);

    const handleMoistureContentChange = (e, dataId) => {
        setBiocharMassBagsState((prevState) => {
            return prevState.map((item) => {
                if (item.get('rowId') === dataId) {
                    item.set('moistureContent', e.target.value);
                }
                return item;
            });
        });
    }
    const handleSubmitData = () => {
        // setLoading(true)
        // console.log(biocharMassBagsState[0].get('disabled'))
        dispatch(postBiocharMassBagMoistureContent(biocharMassBagsState.map((i) => {
            return {
                id: i.get('id'),
                date: i.get('date'),
                user: i.get('user_name'),
                mbId: i.get('mbId'),
                massOfBag: i.get('massOfBag'),
                moistureContent: i.get('moistureContent'),
                measurementPhoto: i.get('measurementPhoto'),
                dryMass: i.get('dryMass'),
                disabled: i.get('disabled')
            }
        }), projectId)).then(() => {
            dispatch(getBiocharMassBags(projectId))
            setLoading(false)
        })
    }

    const handleCopyDown = (mbId, moistureValue) => {
        return () => {
            setBiocharMassBagsState((prevState) => {
                return prevState.map((item) => {
                    if (item.get('mbId') === mbId && !JSON.parse(item.get('disabled'))) {
                        item.set('moistureContent', moistureValue);
                    }
                    return item;
                });
            });
        }
    }

    return (
        <>
            <h2>Biochar Mass - Bags</h2>
            <TableContainer key={0} component={Paper} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                User
                            </TableCell>
                            <TableCell align="left">
                                Date
                            </TableCell>
                            <TableCell align="left">
                                Mb Id
                            </TableCell>
                            <TableCell align="left">
                                Measurement (lbs)
                            </TableCell>
                            <TableCell align="left">
                                Measurement Photo
                            </TableCell>
                            <TableCell align="left">
                                Moisture Content
                            </TableCell>
                            <TableCell align="left">
                                Dry Mass
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {biocharMassBagsState?.map((data, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="left">
                                        {data.get('user')}
                                    </TableCell>
                                    <TableCell align="left">
                                        {moment(data.get('date')).format('MM-DD-YY')}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.get('mbId')}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.get('massOfBag')}
                                    </TableCell>
                                    <TableCell align="left">
                                        <a href={data.get('measurementPhoto')} about="_blank">{data.get('measurementPhoto') ? data.get('measurementPhoto').split('/')[data.get('measurementPhoto').split('/').length - 1] : ''}</a>
                                    </TableCell>
                                    <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            name='moisture content'
                                            value={data.get('moistureContent') === 'null' ? '' : data.get('moistureContent')}
                                            onChange={(e) => handleMoistureContentChange(e, data.get('rowId'))}
                                            type="text"
                                            disabled={!!JSON.parse(data.get('disabled')) ? true : false}

                                        />
                                        {/* {console.log(data.get('moistureContent'))} */}
                                        {(data.get('moistureContent') !== 'null' && !JSON.parse(data.get('disabled'))) ?
                                            <Tooltip
                                                title={
                                                    <h2 style={{ color: 'lightgreen' }}>
                                                        Copy value to all {data.get('mbId')} rows
                                                    </h2>
                                                }
                                            >

                                                <Button onClick={handleCopyDown(data.get('mbId'), data.get('moistureContent'))}><ArrowCircleDownIcon style={{ fontSize: '2rem' }} /></Button>
                                            </Tooltip> : null}
                                    </TableCell>
                                    <TableCell align="left">
                                        {JSON.parse(data.get('dryMass')) === null ? <Typography sx={{ color: 'darkorange' }}>Undetermined</Typography> : data.get('dryMass')}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ position: 'relative', mb: 10 }}>
                {loading ? <CircularProgress sx={{ position: 'absolute', left: '0px', top: '12px' }}></CircularProgress> : <Button variant='contained' sx={{ height: 'auto', mt: 2, ml: 3 }} onClick={handleSubmitData}>Submit Data</Button>}
            </Box>
        </>
    )
}

export default BiocharMassBags