import {
    GET_TIGERCAT_BURNS_GET_NOTES_SUCCESS,
    GET_TIGERCAT_BURNS_GET_NOTES_FAIL,
} from '../../actions/tigercat-burns/types/tigercat-operations-types';

const initialState = {
    noteLogs: []
};

export default function tigercatBurnsOperationsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_TIGERCAT_BURNS_GET_NOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                noteLogs: payload
            };
        case GET_TIGERCAT_BURNS_GET_NOTES_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}