import {
    GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS,
    GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL,
    POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS,
    POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL,
    GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL,
    POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
    POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
    GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
    GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_SUCCESS,
    GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_FAIL,
    POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_SUCCESS,
    POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_FAIL,
    GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_SUCCESS,
    GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_FAIL,
    GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_SUCCESS,
    GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_FAIL,
    POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_SUCCESS,
    POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_FAIL,
    POST_TIGERCAT_BURNS_ADD_BASE_CALLER_SUCCESS,
    POST_TIGERCAT_BURNS_ADD_BASE_CALLER_FAIL,
    POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_SUCCESS,
    POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_FAIL,
    GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_SUCCESS,
    GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_FAIL,
    GET_TIGERCAT_BURNS_GET_BASE_CALLERS_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BASE_CALLERS_FAIL,
    GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS_SUCCESS,
    GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS_FAILURE,
    // 
    POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT_SUCCESS,
    POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT_FAILURE,
} from '../../actions/tigercat-burns/types/tigercat-types';

const initialState = {
    biocharVolumes: [],
    biocharMassBags: [],
    bulkFuelDeliveries: [],
    biocharProductionFuelUses: [],
    feedstockProcessingFuel: [],
    propaneRefills: [],
    feedstockTransports: [],
    callSubmissionLogs: [],
    baseCallers: [],
    loading: true,
};

export default function tigercatBurnsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharVolumes: payload
            };
        case GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharMassBags: payload
            };
        case GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT_FAILURE:
            return {
                ...state,
                loading: false
            }
        case POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkFuelDeliveries: payload
            };
        case GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharProductionFuelUses: payload
            };
        case GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_SUCCESS:
            return {
                ...state,
                loading: false,
                feedstockProcessingFuel: payload
            };
        case GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_SUCCESS:
            return {
                ...state,
                loading: false,
                propaneRefills: payload
            };
        case GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                feedstockTransports: payload
            };
        case GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_ADD_BASE_CALLER_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_ADD_BASE_CALLER_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_SUCCESS:
            return {
                ...state,
                loading: false,
                callSubmissionLogs: payload
            };
        case GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_BASE_CALLERS_SUCCESS:
            return {
                ...state,
                loading: false,
                baseCallers: payload
            };
        case GET_TIGERCAT_BURNS_GET_BASE_CALLERS_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}