export const GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS = 'GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS';
export const GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL = 'GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL';

// API_GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS
// API_POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT

export const GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS_SUCCESS = 'GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS_SUCCESS';
export const GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS_FAILURE = 'GET_TIGERCAT_GET_BIOCHAR_MASS_BAGS_FAILURE';

export const POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT_SUCCESS = 'POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT_SUCCESS';
export const POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT_FAILURE = 'POST_TIGERCAT_POST_BIOCHAR_MASS_MOISTURE_CONTENT_FAILURE';

export const POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS = 'POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS';
export const POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL = 'POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL';

export const GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS = 'GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL = 'GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL';

export const POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS = 'POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS';
export const POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL = 'POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL';

export const GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS = 'GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL = 'GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL';

export const GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_SUCCESS = 'GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_FAIL = 'GET_TIGERCAT_BURNS_GET_FEEDSTOCK_PROCESSING_FUEL_FAIL';

export const POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_SUCCESS = 'POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_SUCCESS';
export const POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_FAIL = 'POST_TIGERCAT_BURNS_POST_PROPANE_REFILL_FAIL';

export const GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_SUCCESS = 'GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_FAIL = 'GET_TIGERCAT_BURNS_GET_PROPANE_REFILL_FAIL';

export const POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_SUCCESS = 'POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_SUCCESS';
export const POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_FAIL = 'POST_TIGERCAT_BURNS_POST_FEEDSTOCK_TRANSPORT_FAIL';

export const GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_SUCCESS = 'GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_FAIL = 'GET_TIGERCAT_BURNS_GET_FEEDSTOCK_TRANSPORT_FAIL';

// Call log

export const POST_TIGERCAT_BURNS_ADD_BASE_CALLER_SUCCESS = 'POST_TIGERCAT_BURNS_ADD_BASE_CALLER_SUCCESS';
export const POST_TIGERCAT_BURNS_ADD_BASE_CALLER_FAIL = 'POST_TIGERCAT_BURNS_ADD_BASE_CALLER_FAIL';

export const GET_TIGERCAT_BURNS_GET_BASE_CALLERS_SUCCESS = 'GET_TIGERCAT_BURNS_GET_BASE_CALLERS_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_BASE_CALLERS_FAIL = 'GET_TIGERCAT_BURNS_GET_BASE_CALLERS_FAIL';

export const POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_SUCCESS = 'POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_SUCCESS';
export const POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_FAIL = 'POST_TIGERCAT_BURNS_ADD_CALL_SUBMISSION_FAIL';

export const GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_SUCCESS = 'GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_FAIL = 'GET_TIGERCAT_BURNS_GET_CALL_SUBMISSION_LOG_FAIL';
