import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import WasteToLandfill from './WasteToLandfill';
import WasteToRecycling from './WasteToRecycling';
import WasteToComposting from './WasteToComposting';
import WasteToBiocharProd from './WasteToBiocharProd';
import WasteWaterTreatment from './WasteToWaterTreatment';

export default function WasteGeneration() {
    const [selected, setSelected] = useState('Waste to Landfill')
    let wasteCategories = ['Waste to Landfill', 'Waste to Recycling', 'Waste to Composting', 'Waste to Water Treatment', 'Waste to Biochar Production']

    if (localStorage.getItem('group') === '1') {
        wasteCategories = wasteCategories.splice(0, 4)
    }

    const outputComponent = () => {
        if (selected === 'Waste to Landfill') {
            return <WasteToLandfill />
        } else if (selected === 'Waste to Recycling') {
            return <WasteToRecycling />
        }
        else if (selected === 'Waste to Composting') {
            return <WasteToComposting />
        }
        else if (selected === 'Waste to Biochar Production') {
            return <WasteToBiocharProd />
        }
        else if (selected === 'Waste to Water Treatment') {
            return <WasteWaterTreatment />
        }
        else {
            return null
        }
    }

    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {wasteCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', marginBottom: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catFive', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={9}>
                {outputComponent()}
            </Grid>
        </>
    );
}
