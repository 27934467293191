import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, CardContent } from '@mui/material';
import { getCarRppbProjects, getCompanyUserCarRppbs } from '../../../../actions/tigercat-burns/tigercat-base-actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import moment from 'moment'

import { getNoteLogs } from '../../../../actions/tigercat-burns/tigercat-operations-actions';

export const NoteLogs = () => {

    const dispatch = useDispatch()
    const [activeCarRppb, setActiveCarRppb] = useState(null);
    const [activeCarRppbProject, setActiveCarRppbProject] = useState(null);

    const carRppbs = useSelector(state => state.tigercatBurnsBaseReducer.carRppbs.data)
    const carRppbProjects = useSelector(state => state.tigercatBurnsBaseReducer.carRppbProjects.data)

    const noteLogs = useSelector(state => state.tigercatBurnsOperationsReducer.noteLogs)

    const { userData } = useSelector(state => state.auth);

    const userCompanyId = userData?.tigercatBurnsUser?.companyId;
    const [hide, setHide] = useState(true);
    const [hideProjects, setHideProjects] = useState(true);

    useEffect(() => {
        if (userCompanyId)
            dispatch(getCompanyUserCarRppbs(userCompanyId))
    }, [dispatch, userCompanyId])

    useEffect(() => {
        if (activeCarRppbProject) {
            dispatch(getNoteLogs(activeCarRppbProject.id))
        }
    }, [dispatch, activeCarRppbProject])

    const handleSetActiveCarRppb = (carRppb) => {
        setActiveCarRppb(carRppb)
        dispatch(getCarRppbProjects(carRppb.id))
    }

    return (
        <Grid>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setHide(!hide)}
                >
                    <Typography>{hide ? "Hide" : "Expand"} CAR RPPBs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                        {carRppbs?.map((carRppb, index) => {
                            return (
                                <Button
                                    sx={{ mr: 2 }}
                                    key={index}
                                    variant={carRppb.id === activeCarRppb?.id ? 'contained' : 'outlined'}
                                    color='primary'
                                    onClick={() => handleSetActiveCarRppb(carRppb)}>
                                    {carRppb.reportingEntityName}
                                </Button>
                            )
                        })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {
                activeCarRppb &&
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => setHideProjects(!hideProjects)}
                    >
                        <Typography>{hideProjects ? "Hide" : "Expand"} Projects</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Typography variant='h5' component='h4' sx={{ mb: 3 }}>
                                CAR RPPB Projects
                            </Typography>
                            <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                                {carRppbProjects?.map((project, index) => {
                                    return (

                                        <Box className="biochar-project" key={index}
                                            sx={activeCarRppbProject?.id === project.id && { background: 'linear-gradient(to bottom right, #535988, #313551)', color: '#FFF', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                                            onClick={() => {
                                                setActiveCarRppbProject(project)
                                            }}>
                                            <Typography sx={{ fontSize: '18px' }}>
                                                {activeCarRppbProject?.id === project.id ? <BookmarkOutlinedIcon sx={{ mb: '-7px' }} /> :
                                                    <BookmarkBorderOutlinedIcon sx={{ mb: '-7px' }} />} {project.id}</Typography>
                                            <Typography sx={project.projectName ? { fontSize: '14px' } : { visibility: 'hidden' }} mt={3}><strong>Project Name:</strong> {project.projectName}</Typography>
                                            <Typography sx={{ fontSize: '14px' }}><strong>Date Created:</strong> {project.createdAt.split('T')[0]}</Typography>
                                        </Box>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {activeCarRppbProject &&
                <Box>
                    <Typography variant='h5' component='h4' sx={{ my: 2.5 }}>
                        Notes Log
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'start', rowGap: 4, columnGap: 4, alignItems: 'start', flexWrap: 'wrap', mt: 0 }}>
                        {/* ITERATE OVER NOTE LOGS HERE */}
                        {/* {[0, 1, 2, 3, 4, 5].map((v, i) => { */}
                        {noteLogs?.data?.map((n, i) => {
                            return (
                                <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', }} key={i}>
                                    <CardContent sx={{ maxWidth: 450, border: '1px solid lightgrey', borderRadius: '10px' }}>
                                        <Typography variant="h6" component="div">
                                            Date: {moment(n.created_at).format('DD-MM-YYYY, h:mm:ss a')}
                                        </Typography>
                                        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>Submitted by: {n.user_name}</Typography>
                                        <Typography variant="body" color="text.secondary" sx={{ display: 'block', mb: 2.5 }}>
                                            {n.note}
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box
                                                component="img"
                                                src={n.note_media}
                                                alt="note-image"
                                                sx={{
                                                    margin: 'auto',
                                                    width: 400, // Set width
                                                    height: 'auto', // Maintain aspect ratio
                                                    borderRadius: 2, // Rounded corners
                                                    boxShadow: 3, // Elevation
                                                }}
                                            />
                                        </Box>
                                    </CardContent>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            }
        </Grid>
    )
}