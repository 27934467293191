import React from 'react'
import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import { DailyCallLog } from './operations/DailyCallLog';
import { NoteLogs } from './operations/NoteLogs';

const Operations = () => {
    const [selected, setSelected] = useState('Daily Call Logs')
    const operationsCategories = ['Daily Call Logs', 'Note Logs']

    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Box marginTop={2}>
                    {operationsCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', mb: 2.5 }}
                                onClick={() => setSelected(c)}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={10}>
                {selected === 'Daily Call Logs' ? <DailyCallLog /> :
                    <NoteLogs />
                }
            </Grid>
        </Grid>
    )
}

export default Operations