import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import faqs from './faqs';
import { postFormData } from './forms';
import carbonfootprint from './carbonfootprint';
import irtRegistries from './landing';
import biocharReporting from './reporting'
import directGhgEmissionsAndRemovalsForDiesel from './carbonfootprint-rework/01-direct-ghg-emissions-and-removals/diesel-reducer'
import directGhgEmissionsAndRemovalsForLpg from './carbonfootprint-rework/01-direct-ghg-emissions-and-removals/lpg-reducer';
import directGhgEmissionsAndRemovalsForPetrol from './carbonfootprint-rework/01-direct-ghg-emissions-and-removals/petrol-reducer';
import directGhgEmissionsAndRemovalsForRefrigerantGas from './carbonfootprint-rework/01-direct-ghg-emissions-and-removals/refrigerant-gas-reducer';
import directGhgEmissionsAndRemovalsForDryIce from './carbonfootprint-rework/01-direct-ghg-emissions-and-removals/dry-ice-reducer';
import directGhgEmissionsAndRemovalsForElectricityPurchased from './carbonfootprint-rework/02-indirect-ghg-emissions-from-imported-energy/eletricity-purchased';
import directGhgEmissionsAndRemovalsForRenewableEnergy from './carbonfootprint-rework/02-indirect-ghg-emissions-from-imported-energy/renewable-energy';
import directGhgEmissionsAndRemovalsForAssets from './carbonfootprint-rework/01-direct-ghg-emissions-and-removals/add-asset-reducer';
import directGhgEmissionsAndRemovalsFileUpload from './carbonfootprint-rework/01-direct-ghg-emissions-and-removals/file-upload-reducer';
import carbonFootprintBase from './carbonfootprint-rework/00-base/base-reducer';
import indirectGhgEmissionsFromOtherSourcesForWasteToLandfill from './carbonfootprint-rework/05-indirect-ghg-emissions-from-other-sources/01-waste-to-landfill-reducer';
import indirectGhgEmissionsFromOtherSourcesForWasteToRecycling from './carbonfootprint-rework/05-indirect-ghg-emissions-from-other-sources/02-waste-to-recycling-reducer';
import indirectGhgEmissionsFromOtherSourcesForWasteToComposting from './carbonfootprint-rework/05-indirect-ghg-emissions-from-other-sources/03-waste-to-composting-reducer';
import indirectGhgEmissionsFromOtherSourcesForWasteToBiocharProduction from './carbonfootprint-rework/05-indirect-ghg-emissions-from-other-sources/04-waste-to-biochar-reducer';
import indirectGhgEmissionsFromProductsForWaterReducer from './carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/01-water-reducer';
import indirectGhgEmissionsFromProductsForWoodenProductsReducer from './carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/03-wooden-products-reducer';
import indirectGhgEmissionsFromProductsForPackagingMaterialsReducer from './carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/04-packaging-materials-reducer';
import indirectGhgEmissionsFromProductsForOtherReducer from './carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/06-other-reducer';
import indirectGhgEmissionsFromProductsForChemicalsAndAdditivesReducer from './carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/02-purchased-chemicals-and-additives-reducer';
import indirectGhgEmissionsFromProductsForWineAndGrapesReducer from './carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/05-wine-and-grapes-reducer';
import indirectGhgEmissionsFromOtherSourcesForWasteToWaterTreatmentReducer from './carbonfootprint-rework/05-indirect-ghg-emissions-from-other-sources/05-waste-to-water-treatment-reducer';
import conservationBurnsReducer from './conservation-burns/conservation-burns-reducer';
import indirectGhgEmissionsFromProductsForCapitalGoodsAndServicesReducer from './carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/07-capital-goods-and-servives-reducer';
import flightsAndAccommodationReducer from './carbonfootprint-rework/03-indirect-ghg-emissions-from-transportation/01-flights-and-accommodation';
import carHireReducer from './carbonfootprint-rework/03-indirect-ghg-emissions-from-transportation/02-car-hire';
import rofBurnsReducer from './rof-burns/rof-burns-reducer';
import tigercatBurnsBaseReducer from './tigercat-burns/tigercat-burns-reducer-base';
import tigercatBurnsReducer from './tigercat-burns/tigercat-burns-reducer';
import indirectGhgEmissionsFromProductsSuppliers from './carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/00-supplier-reducer';
import petrolCardHolderReducer from './carbonfootprint-rework/03-indirect-ghg-emissions-from-transportation/03-petrol-card-holders';
import tigercatBurnsOperationsReducer from './tigercat-burns/tigercat-burns-operations-reducer';

export default combineReducers({
  carbonFootprintBase,
  directGhgEmissionsAndRemovalsForDiesel,
  alert,
  auth,
  faqs,
  postFormData,
  carbonfootprint,
  irtRegistries,
  biocharReporting,
  directGhgEmissionsAndRemovalsForPetrol,
  directGhgEmissionsAndRemovalsForLpg,
  directGhgEmissionsAndRemovalsForRefrigerantGas,
  directGhgEmissionsAndRemovalsForDryIce,
  directGhgEmissionsAndRemovalsForElectricityPurchased,
  directGhgEmissionsAndRemovalsForRenewableEnergy,
  directGhgEmissionsAndRemovalsForAssets,
  directGhgEmissionsAndRemovalsFileUpload,
  indirectGhgEmissionsFromOtherSourcesForWasteToLandfill,
  indirectGhgEmissionsFromOtherSourcesForWasteToRecycling,
  indirectGhgEmissionsFromOtherSourcesForWasteToComposting,
  indirectGhgEmissionsFromOtherSourcesForWasteToBiocharProduction,
  indirectGhgEmissionsFromProductsSuppliers,
  indirectGhgEmissionsFromProductsForWaterReducer,
  indirectGhgEmissionsFromProductsForWoodenProductsReducer,
  indirectGhgEmissionsFromProductsForPackagingMaterialsReducer,
  indirectGhgEmissionsFromProductsForOtherReducer,
  indirectGhgEmissionsFromProductsForChemicalsAndAdditivesReducer,
  indirectGhgEmissionsFromProductsForWineAndGrapesReducer,
  conservationBurnsReducer,
  indirectGhgEmissionsFromProductsForCapitalGoodsAndServicesReducer,
  flightsAndAccommodationReducer,
  carHireReducer,
  rofBurnsReducer,
  tigercatBurnsBaseReducer,
  tigercatBurnsReducer,
  petrolCardHolderReducer,
  tigercatBurnsOperationsReducer,
  indirectGhgEmissionsFromOtherSourcesForWasteToWaterTreatmentReducer
});
